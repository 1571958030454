import React from "react";

import './Cancelation.css'
import CancelationForm from '../CancelationForm/CancelationForm';
import ContactInformation from '../../../shared/components/ContactInformation/ContactInformation';

const Cancelation = props => {
    
    const cancelInformation ={
        contactInformationTitle : "Notify us if you have a cancellation request",
        contactInformationWeek : "Monday - Friday 9:00 AM - 09:00 PM EST",
        contactInformationWeekend : "Saturday - Sunday 9:00 AM - 05:00 PM EST"
    }


    return (
        <section className="Cancelation__main-container">
                <div className="Cancelation__contact-information-container" >
                    <div className="Cancelation__contact-information">
                        <ContactInformation props={cancelInformation}></ContactInformation>
                    </div>
                </div>
                <div className="Cancelation__contact-form-container"  >
                    <div className="Cancelation__contact-form"  >
                        <CancelationForm></CancelationForm>
                    </div>
                </div>  
        </section>
    );
};

export default Cancelation;