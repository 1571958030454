import React from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Faq from '../components/Faq/Faq';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";


import aboutUsBannerImg from '../../utils/assets/aboutUs/our-team.png';
import ourServicesImg from '../../utils/assets/aboutUs/our-services.png';


const AboutUs = props => {
    const bannerSectionInfo =
     {
        cardTitle: "Managing your personal information privacy has never been easier    ",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg
     }

     const productDescription = {
        title : "Learn more about us",
        img : ourServicesImg
     }
          
    return(
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="About us"/>
           <ProductDescription props={productDescription} />
            {/* <SectionInfo title={'FQA'} color={'#6e7de0'}/> */}
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;