import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import localStorageService from "../../../utils/helpers/services/localStorageService";
import {IDENTITY_SERVICES_PRODUCTS} from '../../../utils/helpers/constants/localStorageKeys';


import './PriceCard.css';


const PriceCard = ({ props }) => {
    const dolars = props.subscriptionPrice
    const cents = props.subscriptionPrice;

    const [currentPath, setCurrentPath] = useState('');
    

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);


    
   const  handleGoToCheckout = () =>{
        const productInformation = {
            subscriptionType: props.subscriptionType,
            subscriptionPrice: props.subscriptionPrice,
            subscriptionTrialInfo: props.subscriptionTrialInfo,
            subscriptionTrialPrice : props.subscriptionTrialPrice,
            subscriptionDescription: props.subscriptionDescription,
            subscriptionButtonText: props.subscriptionButtonText
        }
        localStorageService.saveProduct( IDENTITY_SERVICES_PRODUCTS.CARD_PRODUCT_SUBSCRIPTON, productInformation);
        sessionStorage.setItem('sectionName', 'price');
        window.open('/checkout', '_self');
    }
    const cardHeigth = currentPath === '/checkout' ? '25em' : 'auto';


    return (


        <div className="PriceCard__container" style={{height : cardHeigth}}>
            <div className="PriceCard__card-container">
                <div className="PriceCard__card-title" >
                    <h1 >{props.subscriptionType}</h1>
                </div>
                {
                    (currentPath !== '/checkout' || currentPath === '/checkout' ) && <div className="PriceCard__card-trial-info" >
                        <p>{props.subscriptionTrialInfo} <b>${props.subscriptionTrialPrice}</b></p>
                    </div>
                }

                <div className="PriceCard__card-price">
                    <p>
                        <span className="PriceCard__dolar-price">{`$${dolars.split('.')[0]}`}</span><span className="PriceCard__cents-price">{`.${cents.split('.')[1]}`}</span>
                    </p>
                </div>

                <div className="PriceCard__card-description" >
                    <p>
                        {props.subscriptionDescription}
                    </p>
                </div>
                {(currentPath !== '/checkout') && <div className="PriceCard__button-container">
                    <div className="PriceCard__button">
                        <button onClick={(e) => { e.preventDefault(); handleGoToCheckout(); }}>
                            BUY
                        </button>
                    </div>
                </div>}


                {(currentPath === '/price') && <div className="PriceCard__subscription-definition-container"> <p>
                    Please have a look at the <Link to="/terms-of-service">terms and conditions</Link> and
                    contact us if you have any questions or concerns.
                    Your monthly membership fee will be automatically billed
                    to your designated billing source at the end of the period.
                    If you wish to <Link to="/cancel-service">cancel the service</Link>, remember to notify us
                    of your decision by calling or completing the cancellation
                    form.
                </p></div>}

            </div>
        </div>
    );
};

export default PriceCard;