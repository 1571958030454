import React from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Benefit from "../components/Benefit/Benefit";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";

import protectYourIdentityBannerImg from '../../utils/assets/home/protect-your-identity.png';
import protectAndSecurityBannerImg from '../../utils/assets/home/protect-and-secure-technology.png';
import identityWarningSystemBannerImg from '../../utils/assets/home/identity-warning-system.png';
import identityProtectionImg from '../../utils/assets/home/identity-protection-and-security-services.png';


const Home = props => {
    const bannerSectionInfo =
    [
        {
            cardTitle: "Get smart about protecting your online identity",
            cardBannerPosition : 1,
            cardButtonText: "join us",
            lengendBackground: protectYourIdentityBannerImg
        },
        {
            cardTitle: "Identity recovery and restoration have never been easier",
            cardBannerPosition : 2,
            cardButtonText: "Start now",
            lengendBackground: identityWarningSystemBannerImg
        },
        {
            cardTitle: "Advanced identity protection and security technology",
            cardBannerPosition : 3,
            cardButtonText: "Sign Up",
            lengendBackground: protectAndSecurityBannerImg
        }

    ]

    const productDescription = {
        title: "What do we offer?",
        img: identityProtectionImg 
    }

    const subscriptionInformation = [
        {       
            subscriptionType: "MONTH",
            subscriptionPrice: "44.99",
            subscriptionTrialInfo: "Trial for 24 days at",
            subscriptionTrialPrice : "3.49",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {
            img: props.img,
            title : "Ready to relax and rest easy knowing you and your loved ones are covered 24/7?",
            description : "Find the protection and security you need to keep your identity and personal information safe from theft. Save money, time, and peace of mind knowing we have your back."
        }
    ]

 
    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo[0]}/>
            <ProductDescription props={productDescription}/>
            <BannerSection props={bannerSectionInfo[1]}/>
            <Benefit/>
            <BannerSection props={bannerSectionInfo[2]}/>
            <SectionInfo title={"Join our Community and get started"} color={"#0378a6"}/> 
            <SubscriptionPlan  props={subscriptionInformation} />
        </React.Fragment>
    );
};

export default Home;