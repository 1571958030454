import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './ProductDescription.css';

const ProductDescription = ({ props }) => {
    const location = useLocation();
    let paragraphContent;


    console.log(location.pathname)
    //const { paragraphs, instructions } = props;


    switch (location.pathname) {    
        case '/':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        Identity Services is a monthly subscription service designed to 
                        protect you from identity theft by providing a program for the
                        prevention, recovery, and response to victims of this type of
                        digital crime.
                        </b>
                    </p>
                    <br />
                    <p>
                        Don't be a victim; if a thief steals your identity, it can take 
                        many months to discover, not to mention approximately 300
                        hours and up to 44 months to fix the problems.
                    </p>
                    <br />
                    <p>
                    Affecting more than a billion Americans and costing millions of
                    dollars, identity theft has become one of the most commons
                    crimes today. 
                    </p>
                    <br />
                    <p>
                    With 24/7/365 virtual support, our plan eliminates the stress,
                    anxiety, and worry of your identity being stolen.
                    millions of dollars in losses. <b> Protecting
                    you and your family is a top priority for us.
                    </b>          
                    </p>
                </>
            );
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                       <b>
                       Identity Services is connected to an identity protection and
                       privacy management service provider called Privacymaxx.
                       </b>
                    </p>
                    <br/>
                    <p>
                    Access your account by logging in or by visiting
                <b>&nbsp;portal.identity-services-llc.com </b> and entering your account ID. Get an 
                    innovative service to help protect and secure your personal
                    information.
                    </p>
                    <br/>
                    <p>
                    Protect yourself and make identity theft a thing of the past 
                    with our <b> 24/7 expert assistance, security upgrades, and
                    protection tools.
                    </b>
                    </p>
                </>
            );
            break;

        default:
            paragraphContent = null;

    }

    return (
        <section className="ProductDescription__main-container" id="about-us">
            <div className="ProductDescription__explanation-container">
                <div className="ProductDescription__title-container">
                    <h1>{props.title}</h1>
                </div>
                <div className="ProductDescription__explanation-container">
                    {paragraphContent}
                </div>
            </div>

            <div className="ProductDescription__img-container">
                <div className="ProductDescription__img-inner-container">
                    <img src={props.img} alt={props.title} className="ProductDescription__img" />
                </div>
            </div>
        </section>
    );
};

export default ProductDescription;