export const PROJECT = {
    DOMAIN : 'www.identity-services-llc.com',
    MERCHAN_ID : 59,
    AMOUNT : '3.49',
    BUILD_ORDER : '1',
    CAPTURA_DELAY : '0',
    PRODUCT_COUNT : '1',
    PRODUCT_ID : '3',
    PRODUCT_KU_1 : 'identityServices_product1',
    PRODUCT_QT_Y_1 : '1',
    BINCHECK : '1',
    SKIP : '1'
}