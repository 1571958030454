import React, { useState } from "react";

import './Faq.css';

import Accordion from './components/Accordion/Accordion';
import ContactCard from '../../../shared/components/ContactCard/ContactCard';



const Faq = props => {

    const faqsInformation =
        [
            {
                question: "How do I log in to my account?",
                answer:
                    "A lot.",
                open: false
            },
            {
                

                question:
                    "What does your service cover?",
                answer: "This many!",
                open: false
            },
            {
                question: "How ID Early Warning System service works?",
                answer: "You! The viewer!",
                open: false
            },
            {
                question:
                    "How Credit Security Freezes works?",
                answer: "This many!",
                open: false
            },
            {
                question:
                    "How Fraud Alert Assistance works?",
                answer: "This many!",
                open: false
            },
            {
                question:
                    "How Junk Mail Reduction works?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "What kind of information do we need from you?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "How Lost Wallet Service works?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "Who do you call if your identity is stolen?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "What is Privacymaxx?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "Are identity recovery services extra?",
                answer: "This many!",
                open: false
            }
            ,
            {
                question:
                    "How do I cancel?",
                answer: "This many!",
                open: false
            }

            


            
            
        ]

    const [faqs, setFaqs] = useState(faqsInformation)

    const toggleFAQ = index => {
        setFaqs(
            faqs.map((faq, i) => {
                if (i === index) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }

                return faq;
            })
        );
    };

    return (
        <>
        {/* <section className="Faq__main-container">
            <div className="Faq__faqs-container" >   
                <div className="Faq__faqs columns faqs">
                    {faqs.map((faq, index) => (
                        <Accordion faq={faq} index={index} key={index} toggleFAQ={toggleFAQ}></Accordion>
                    ))}
                </div>
            </div>
        </section> */}
        <section>
            <div className="Faq__customer-service-container">
                <ContactCard/>
            </div> 
        </section>
        </>

    );
};

export default Faq;