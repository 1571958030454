import React, {useState} from "react";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";

import './ContactForm.css'
import Loader from "../../../shared/components/Loader/Loader";
import error from "../../../utils/helpers/errors/formErrors";
import validate from "../../../utils/helpers/validators/dataValidation";
import { DATA_COMES_FROM } from "../../../utils/helpers/constants/formValue";
import { sendContactEmail } from "../../../utils/helpers/services/contactService";
import { SERVER_RESPONSE } from "../../../utils/helpers/constants/serverResponse";


const ContactForm = props => {

  let styleForUnconfirmRecaptcha = {
    backgroundColor : '#73b4ff',
    border : '1px solid #010d26',
    color : '#000000'
  }
  let styleForConfirmRecaptcha = {
      backgroundColor : '#010d26',
      border : 'none',
      color : '#ffffff'
  }
    const [recaptcha, setRecaptcha] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorState, setErrorState] = useState({
        firstName: false,
        phoneNumber: false,
        email : false,
        message: false,
      });
      
      const [errorMessages, setErrorMessages] = useState({
        firstName: '',
        phoneNumber: '',
        email : '',
        message: '',
      });

      const [formData, setFormData] = useState({
        firstName: '',
        phoneNumber: '',
        email : '',
        message: '',
       
    });

    const handleOnChange = ( e ) =>{
        const { name,  value } = e.target;
        const {isValid, theError} = error.validateField( name, value );

        setErrorState((prevErrorState) => ({ ...prevErrorState, [name]: !isValid }));
        setErrorMessages((prevErrorMessages) => ({ ...prevErrorMessages, [name]: isValid ? '' : theError }));
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    const handleOnSubmit = ( e )=>{
        e.preventDefault();
        let isFormInvalid = {
          isValid : true,
          theError : ''
        } 
        if( validate.requieredFieldsToSentData(DATA_COMES_FROM.CONTACT_FORM, formData) === false ){
            Swal.fire({
                title: 'Error!',
                text: 'You must fill out the fields to continue',
                icon: 'error',
                confirmButtonText: 'Sure!', 
                confirmButtonColor: '#010D26'
              });
            return;
        }
        
        validate.allFieldsBeforeTheCheckout(formData, DATA_COMES_FROM.CONTACT_FORM).forEach(( response )=>{
          if ( response.isValid === false ){
              isFormInvalid = {
                  isValid : response.isValid,
                  theError : response.typeError
              }
          }
      })


      if( isFormInvalid['isValid']){

        setLoading(true);
        sendContactEmail(formData)
        .then( result =>{
          setLoading(false);
          console.log( result )
          if(result === SERVER_RESPONSE.SUCCESS){
            console.log("Data to sent to backend");
        console.log( formData );
        Swal.fire({
            title: "Message sent!",
            text: "One our representatives will contact you shortly",
            icon: "success",
            confirmButtonColor : '#010D26'
          }).then((result)=>{
            if( result.isConfirmed || result.isDismissed){
              setFormData({
                firstName: '',
                phoneNumber: '',
                email: '',
                message: '',
              });
              setErrorState({
                firstName: false,
                phoneNumber: false,
                email: false,
                message: false,
              });
              setErrorMessages({
                firstName: '',
                phoneNumber: '',
                email: '',
                message: '',
              });
              sessionStorage.clear();
              window.open('/', '_self');
            }
          });
          }
          else{
            setLoading(false);
            Swal.fire({
              title: "Error",
              text: "Error in the server. Try again, please",
              icon: "error",
              confirmButtonColor : '#010D26'
            })
          }
        })
        .catch( error =>{
          setLoading(false);
          console.log( error );
          Swal.fire({
            title: "Error",
            text: "Error sending contact email. Please, try again",
            icon: "error",
            confirmButtonColor : '#010D26'
          })
        })
      }else{
        Swal.fire({
          title: 'Error!',
          text: `${isFormInvalid['theError']}`,
          icon: 'error',
          confirmButtonText: "I am going to change it",
          confirmButtonColor: '#010D26'
        });
        return;
      }
    }

    const resetFormDataValues = ()=>{
        setFormData({
            firstName: '',
            phoneNumber: '',
            email: '',
            message: '',
          });
          setErrorState({
            firstName: false,
            phoneNumber: false,
            email: false,
            message: false,
          });
          setErrorMessages({
            firstName: '',
            phoneNumber: '',
            email: '',
            message: '',
          });
    }




    return (
      loading ? <Loader isLoading={loading}></Loader> :
        (
          <section className="ContactForm__main-form-container">
            <form onSubmit={handleOnSubmit}>
                <div className="mb-3 mt-3">
                    <label htmlFor="firstName" className="form-label">Full Name</label>
                    <input type="text"
                           className="form-control" 
                           id="firstName" 
                           name="firstName" 
                           aria-describedby="firstName" 
                           placeholder="Your full name" 
                           required 
                           autoComplete="off" 
                           onChange={handleOnChange} 
                           value={formData.firstName}/>
                    {errorState.firstName && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.firstName}</span>}
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                    <input type="text" 
                           className="form-control" 
                           id="phoneNumber" 
                           name="phoneNumber" 
                           placeholder="(+1) xxx xxx xxxx" 
                           required 
                           autoComplete="off" 
                           onChange={handleOnChange} 
                           value={formData.phoneNumber}/>
                    {errorState.phoneNumber && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.phoneNumber}</span>}
                </div>
                <div className="mb-3 mt-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input type="email" 
                           className="form-control" 
                           id="email" 
                           name='email' 
                           aria-describedby="email" 
                           placeholder="example@example.com" 
                           required 
                           autoComplete="off" 
                           onChange={handleOnChange} 
                           value={formData.email}/>
                    {errorState.email && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.email}</span>}
                </div>

                <div className="form-floating mt-3">
                    <textarea className="form-control" 
                              placeholder="Leave a comment here" 
                              id="message" 
                              name="message" 
                              onChange={handleOnChange} 
                              value={formData.message} 
                              required 
                              autoComplete="off">
                              </textarea>
                    <label htmlFor="message" style={{color: '#d8d7d7'}}>Message</label>
                    {errorState.message && <span className="text-danger" style={{fontWeight : 'bold'}}>{errorMessages.message}</span>}
                </div>
                <div className="ContactForm__recaptcha-container">
                        <ReCAPTCHA
                    sitekey={process.env.REACT_APP_RECAPTCHA}
                    onChange={(val)=> setRecaptcha(val)}
                    />
                    </div>
                <div className="ContactForm__main-button-container mt-4">
                   <div className="ContactForm__button-container">
                   <button type="button" className=" ContactForm__btn mb-2 mt-2" onClick={resetFormDataValues}>Reset</button>
                    <button type="submit" className=" ContactForm__btn ContactForm__send-btn mb-2 mt-2" disabled={!recaptcha} style={recaptcha ? styleForConfirmRecaptcha: styleForUnconfirmRecaptcha }>Send</button>
                   </div>
                </div>


            </form>
        </section>
        )
    );
};

export default ContactForm;