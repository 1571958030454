import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './BannerSection.css';

const  BannerSection = ( {props} ) => {

    return(
        <section className="BannerSection__main-section-container" style={{backgroundImage: `url(${props.lengendBackground})`}} id="home"> 
            <CardInfo props={props}></CardInfo>
        </section> 
    );
};


const CardInfo = ( { props }) =>{
    let cardBodyText;
    const cellphone = "8555517253";
    const history = useHistory();
    const goToHandle = () => {
        switch( window.location.pathname ){
            case '/':
                if ( props.cardBannerPosition === 1 || props.cardBannerPosition === 2){
                    history.push('/contact')
                }
               
                else {
                    history.push('/price')
                }   
                break;
            case  '/about-us':
            case '/price':
            case '/privacy-policy':
                history.push('/contact');
            break;

            case '/terms-of-service':
            case '/cancel-service':
            window.location.href = `tel:${cellphone}`;
            break;
                default:
                    break;
        }
    }
    

    switch( window.location.pathname ){
        case '/':
            if( props.cardBannerPosition === 1){
                   cardBodyText = (
                    <p>
                        Our next-generation technology specializes in
                        <span className="CardInfo__important-text"> protecting and securing your identity and your
                        family's online privacy.</span>
                    </p>
                   ) 
            }
            else if (props.cardBannerPosition === 2){
                cardBodyText = (
                    <p>
                        Have peace of mind knowing you have the experts
                        in identity protection and security management
                        behind you.<span className="CardInfo__important-text"> &nbsp;Get all the best tools to help protect
                        against Identity Theft in one place.
                        </span>
                    </p>
                )
            }
            else{
                cardBodyText =(
                    <p>
                        Save time, money and headaches with our services.
                        Discover the benefits of having us protected 24/7.
                    </p>
                )
            }
            break;

        case '/price':

             cardBodyText=(
                <p>
                    To keep thieves out, our next-generation system 
                    offers a <span className="CardInfo__important-text"> variety of valuable tools at an affordable
                    price, backed by 24/7 support.
                    </span>
                </p>
             )

            break;
        
        case '/contact':
            cardBodyText = (
                <p>
                    Take advantage of our online profile protection 
                    advice, expert legal assistance, and security 
                    configuration upgrades. <span className="CardInfo__important-text">Make a difference in your
                    digital life.  
                    </span>

                </p> 
            )
            break;
        
        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">You can cancel your service very easily.</span> In order to
                    help us improve your experience, please let us know
                    the reason why you wish to cancel.  
                </p>
            )
                
            break;

        case '/terms-of-service':
            cardBodyText=(
                <p>
                    If you have any questions or would like to receive
                    more information, <span className="CardInfo__important-text">please feel free to contact us!</span> 
                </p>
            )
            break;

        case '/privacy-policy':
                cardBodyText=(
                    <p>
                        At Identity Services, the privacy of your personal 
                        information is important to us. If you have any
                        questions or concerns, <span className="CardInfo__important-text">Please contact us!</span>
                    </p>
                )
        break; 
        case '/refund-policy':
                    cardBodyText=(
                        <p>
                            <span className="CardInfo__important-text">Letting us know what went wrong and how we can
                            improve would be appreciated.</span> If you would like to
                            request a refund for your purchase, please follow 
                            these steps 
                        </p>
                    )
        break;
        case '/about-us': 
            cardBodyText=(
                <p>
                    We offer a full range of tools to help keep our 
                    customers' identities safe and secure.
                </p>
            )
            break;
        case '/checkout':
            cardBodyText=( 
                <p>
                     With our exclusive plan, you can clear your doubts,
                     get incredible advice, get next-generation tools, and
                     have 24/7 access to the experts in the field.
                </p>
            )
            break;
        default :
           cardBodyText = null;
        break;
    }

    
    return(
       
        <div className="BannerSection__main-card-info-container">

            <div className="BannerSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="BannerSection__main-body-container">
                {cardBodyText}
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="BanneSection__main-btn-container">
                        <div className="BannerSection__main-button-container">
                        <div className="BannerSection__inner-button-container">
                            <a href="" onClick={(e) => { e.preventDefault(); goToHandle()}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                    </div>
                ) : (
                    <div className="BannerSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default BannerSection;